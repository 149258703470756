import type { Country, addressAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { FORM_VALID, maxLength, required } from '@ds/components/Form'

import { addressFieldsTranslations as translations } from '../translations'
import type { CountryRules, ValidatorWithI18n } from '../types'
import { getAllStates, getRulesByCountry } from '../utils'

import { InputAddressPhoneValidators } from './InputAddressPhoneValidators'

const MAX_LENGTH = 254

const rulesPerCountryValidation: ValidatorWithI18n<
  addressAPI.AddressFieldsValues
> = (i18n) => (field, values) => {
  const { country } = values
  let invalid

  if (!isEmpty(country)) {
    const rules = getRulesByCountry(country as CountryRules)

    Object.values(rules).forEach((rule) => {
      if (!rule.regexp.test(field)) {
        invalid = i18n(rule.translation)
      }
    })
  }

  return invalid ?? FORM_VALID
}

const legalValidator: ValidatorWithI18n<addressAPI.AddressFieldsValues> =
  (i18n) => (_, values) => {
    return values.legal ? FORM_VALID : i18n(translations.required)
  }

const stateOrProvinceValidation: ValidatorWithI18n<
  addressAPI.AddressFieldsValues
> = (i18n) => (_, values) => {
  if (isEmpty(getAllStates(values.country as Country))) {
    return FORM_VALID
  }

  return !isEmpty(values.stateOrProvince)
    ? FORM_VALID
    : i18n(translations.required)
}

export const AddressFieldsValidators = (i18n: ReturnType<typeof useI18n>) => ({
  city: {
    required: required(i18n(translations.required)),
    valid: maxLength(MAX_LENGTH, i18n(translations.invalidLength)),
  },
  company: {
    valid: maxLength(MAX_LENGTH, i18n(translations.invalidLength)),
  },
  country: {
    required: required(i18n(translations.required)),
  },
  firstName: {
    required: required(i18n(translations.required)),
    maxLength: maxLength(MAX_LENGTH, i18n(translations.invalidLength)),
    valid: rulesPerCountryValidation(i18n),
  },
  firstNamePronunciation: {
    required: required(i18n(translations.required)),
    valid: maxLength(MAX_LENGTH, i18n(translations.invalidLength)),
  },
  lastName: {
    required: required(i18n(translations.required)),
    maxLength: maxLength(MAX_LENGTH, i18n(translations.invalidLength)),
    valid: rulesPerCountryValidation(i18n),
  },
  lastNamePronunciation: {
    required: required(i18n(translations.required)),
    valid: maxLength(MAX_LENGTH, i18n(translations.invalidLength)),
  },
  legal: {
    valid: legalValidator(i18n),
  },
  phone: InputAddressPhoneValidators(i18n),
  postalCode: {
    required: required(i18n(translations.required)),
  },
  publicCompanyName: {
    valid: maxLength(MAX_LENGTH, i18n(translations.invalidLength)),
  },
  stateOrProvince: {
    valid: stateOrProvinceValidation(i18n),
  },
  street: {
    required: required(i18n(translations.required)),
    valid: maxLength(MAX_LENGTH, i18n(translations.invalidLength)),
  },
  street2: {
    valid: maxLength(MAX_LENGTH, i18n(translations.invalidLength)),
  },
})
