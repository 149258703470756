import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { required } from '@ds/components/Form'
import { validPhoneNumber } from '@ds/components/InputPhone'

import { phoneTranslations } from '../translations'

export const InputAddressPhoneValidators = (
  i18n: ReturnType<typeof useI18n>,
) => ({
  REQUIRED: required(i18n(phoneTranslations.required)),
  VALID: validPhoneNumber(i18n(phoneTranslations.invalid)),
})
