import {
  CHARACTERS_RULE,
  MAX_LENGTH_RULE,
  MIN_LENGTH_RULE,
  NOT_A_NUMBER_RULE,
} from '../../constants'
import translations from '../../translations/rules.translations'
import type { CountryRules, Rules } from '../../types'

export function getRulesByCountry(country: CountryRules): Rules | object {
  return CHARACTERS_RULE[country]
    ? {
        characters: {
          regexp: CHARACTERS_RULE[country] ?? '',
          translation: translations.invalidCharacters,
        },
        minLength: {
          regexp: MIN_LENGTH_RULE,
          translation: translations.minLength,
        },
        maxLength: {
          regexp: MAX_LENGTH_RULE,
          translation: translations.maxLength,
        },
        integer: {
          regexp: NOT_A_NUMBER_RULE,
          translation: translations.invalidInteger,
        },
      }
    : {}
}
