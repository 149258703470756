import type { Country } from '@backmarket/http-api'

import { FIELDS_STYLE } from '../../constants'
import {
  type Field,
  type FieldNameStyle,
  type FieldsNameStyle,
} from '../../types'

export function getFieldsStyle({
  country,
}: {
  country: Country
}): FieldsNameStyle {
  return (
    FIELDS_STYLE[country as keyof typeof FIELDS_STYLE] ?? FIELDS_STYLE.default
  )
}

export function getFieldStyle({
  country,
  fieldName,
}: {
  country: Country
  fieldName: FieldNameStyle
}): Field {
  const fieldStyle = getFieldsStyle({ country })

  return fieldStyle
    ? fieldStyle[`${fieldName}`]
    : { order: 0, shouldDisplay: false }
}
